// uses https://github.com/xiaolin/react-image-gallery

import * as React from 'react';
import { graphql } from 'gatsby';
import ImageGallery from 'react-image-gallery';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ImageSlider = props => {
  var propsClone = { ...props };

  return (
    <ImageGallery
      renderItem={item => (
        <GatsbyImage image={getImage(item.original)} alt="slider image" />
      )}
      renderThumbInner={item => (
        <GatsbyImage
          image={getImage(item.thumbnail)}
          alt="slider image thumbnail"
        />
      )}
      {...propsClone}
    />
  );
};

export const VipImageSlider = graphql`
  fragment VipImageSlider on FileConnection {
    thumbnails: nodes {
      childImageSharp {
        gatsbyImageData(
          width: 100
          height: 100
          placeholder: BLURRED
          quality: 30
          transformOptions: { cropFocus: ENTROPY }
        )
      }
    }
    originals: nodes {
      childImageSharp {
        gatsbyImageData(width: 800, placeholder: BLURRED)
      }
    }
  }
`;

export default ImageSlider;

export const joinItemImages = (originals, thumbnails) => {
  if (originals.length !== thumbnails.length) return [];

  return originals.map((item, index) => ({
    original: item,
    thumbnail: thumbnails[index],
  }));
};
