import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import ImageSlider, { joinItemImages } from '../components/imageSlider';

const Gallery = ({ data }) => {
  var pageTitle = 'Gallery';

  return (
    <Layout
      slug="gallery"
      title={pageTitle}
      description="View the different suite options we have available!"
    >
      <h1>{pageTitle}</h1>
      <p>
        Our cat boarding suites are specially designed to create a peaceful and
        relaxing yet mentally stimulating stay for your feline family members.
        All cats have their own private suite; however, if you have multiple
        feline friends they are very welcome to stay together. If your cat has a
        small canine friend we can discuss boarding them together.
      </p>
      <p>
        All cat suites have windows facing to the outdoors with ledges to
        provide a better view. The large, interior windows which look to the
        inside of the kennel facility allow your cat to enjoy watching our staff
        go about their day. We have also focused on creating vertical spaces to
        allow your cat plenty of areas to climb and lounge. Our cat rooms are
        designed with special noise cancelling insulation to provide a quiet,
        calm environment.
      </p>
      <p>
        Very Important Pets is also one of the very few select facilities that
        can provide our feline visitors with a fully secure indoor kennel that
        includes an optional outdoor portion. The outdoor runs are fully
        enclosed and have a wire roof overtop to prevent your cat from climbing
        out. Our outdoor cat yards are on the opposite side from the dogs so
        they are able to enjoy the fresh air in peace.
      </p>

      <ImageSlider
        items={joinItemImages(data.allFile.originals, data.allFile.thumbnails)}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gallery/cat_boarding" } }) {
      ...VipImageSlider
    }
  }
`;

export default Gallery;
